<template>
  <div>
    <b-overlay :show="isSubmitting" rounded="sm">
      <form-wizard
        color="#D71064"
        :title="null"
        :subtitle="null"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Pickup tab -->
        <tab-content title="Pickup" :before-change="PickupForm">
          <b-card>
            <div class="row justify-content-center align-items-center">
              <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
                <img
                  :src="require('@/assets/images/logo/delivery.png')"
                  class="img-fluid"
                />
                <b-card-text class="h1 mb-1 font-large-1 text-primary">
                  Pickup Information
                </b-card-text>
                <p>Set up location for the item pickup.</p>
                <!-- <p class="text-danger">Need help posting order?</p> -->
              </div>
              <div class="col-xs-8 col-sm-8 col-md-5 col-lg-5">
                <b-container
                  class="rounded box-shadow-1 d-flex justify-content-center"
                >
                  <validation-observer ref="PickupRules">
                    <b-card
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="col-12 col-lg-6 w-100">
                              <b-form-group label="FIRST NAME">
                                <validation-provider
                                  #default="{ errors }"
                                  name="First Name"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="form.first_name"
                                    type="text"
                                    placeholder="First name"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Last Name"
                                rules="required"
                                class="w-100"
                              >
                                <b-form-group label="LAST NAME">
                                  <b-form-input
                                    v-model="form.last_name"
                                    type="text"
                                    placeholder="Last name"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="CONTACT">
                                <validation-provider
                                  #default="{ errors }"
                                  name="contact"
                                  rules="required|digits:11"
                                  class="w-100"
                                >
                                  <b-form-input
                                    name="contact"
                                    v-model="form.contact"
                                    type="text"
                                    placeholder="Phone number"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="PICKUP ADDRESS">
                                <validation-provider
                                  #default="{ errors }"
                                  name="pickup address"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-input
                                    v-model="form.address"
                                    name="pickup address"
                                    type="text"
                                    placeholder="Enter pickup location"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="REMARKS">
                                <validation-provider
                                  #default="{ errors }"
                                  name="remarks"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-input
                                    name="remarks"
                                    v-model="form.remarks"
                                    type="text"
                                    placeholder="What would you want driver to note"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="PRODUCT CODE">
                                <b-form-input
                                  name="product code"
                                  type="text"
                                  placeholder="Optional"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="col-12 col-lg-6 w-100">
                              <b-form-group label="PICKUP DATE">
                                <validation-provider
                                  #default="{ errors }"
                                  name="pickup date"
                                  rules="required"
                                >
                                  <flat-pickr
                                    class="form-control"
                                    v-model="form.pickup_date"
                                    placeholder="Choose Date"
                                    readonly="readonly"
                                    :config="{
                                      minDate: 'today',
                                    }"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                              <validation-provider
                                #default="{ errors }"
                                name="pickup time"
                                rules="required"
                                class="w-100"
                              >
                                <b-form-group label="PICKUP TIME">
                                  <flat-pickr
                                    class="form-control"
                                    v-model="form.pickup_time"
                                    placeholder="Choose Time"
                                    readonly="readonly"
                                    :config="{
                                      enableTime: true,
                                      noCalendar: true,
                                    }"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </b-form-group>
                              </validation-provider>
                              <small class="text-danger text-xs">
                                Additional Lpoint will be charged during peak
                                hours.
                              </small>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </validation-observer>
                </b-container>
              </div>
            </div>
          </b-card>
        </tab-content>

        <!-- Delivery  -->
        <tab-content title="Delivery" :before-change="DeliveryForm">
          <b-card>
            <div class="row justify-content-center align-items-center">
              <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
                <img
                  :src="require('@/assets/images/logo/delivery.png')"
                  class="img-fluid"
                />
                <b-card-text class="h1 mb-1 font-large-1 text-primary">
                  Delivery(ies) Information
                </b-card-text>
                <p>Set up location for the item(s) to be delivered.</p>
                <!-- <p class="text-danger">Need help posting order?</p> -->
              </div>
              <div class="col-xs-8 col-sm-8 col-md-5 col-lg-5">
                <b-container
                  class="rounded box-shadow-1 d-flex justify-content-center"
                >
                  <validation-observer ref="DeliveryRules">
                    <b-card
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="col-12 col-lg-6 w-100">
                              <b-form-group label="FIRST NAME">
                                <validation-provider
                                  #default="{ errors }"
                                  name="First Name"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="form.deliveries[0].first_name"
                                    type="text"
                                    placeholder="First name"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Last Name"
                                rules="required"
                                class="w-100"
                              >
                                <b-form-group label="LAST NAME">
                                  <b-form-input
                                    v-model="form.deliveries[0].last_name"
                                    type="text"
                                    placeholder="Last name"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="CONTACT">
                                <validation-provider
                                  #default="{ errors }"
                                  name="contact"
                                  rules="required|digits:11"
                                  class="w-100"
                                >
                                  <b-form-input
                                    v-model="form.deliveries[0].contact"
                                    name="contact"
                                    type="text"
                                    placeholder="Phone number"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="DESTINATION ADDRESS">
                                <validation-provider
                                  #default="{ errors }"
                                  name="destination address"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-input
                                    v-model="form.deliveries[0].address"
                                    name="destination address"
                                    type="text"
                                    placeholder="Enter a location"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="REMARKS">
                                <validation-provider
                                  #default="{ errors }"
                                  name="remarks"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-input
                                    v-model="form.deliveries[0].remarks"
                                    name="remarks"
                                    type="text"
                                    placeholder="What would you want driver to note"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="IMAGE OF ITEM">
                                <validation-provider
                                  #default="{ errors }"
                                  name="image"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-file
                                    v-model="form.item_image"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    accept="image/*"
                                    @change="onFileUpload($event)"
                                    id="input"
                                  ></b-form-file>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </validation-observer>
                </b-container>
              </div>
            </div>
          </b-card>
        </tab-content>

        <!-- Summary  -->
        <tab-content title="Summary" :before-change="SummaryForm">
          <b-card>
            <div class="row justify-content-center align-items-center">
              <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
                <img
                  :src="require('@/assets/images/logo/delivery.png')"
                  class="img-fluid"
                />
                <b-card-text class="h1 mb-1 font-large-1 text-primary">
                  Summary Information
                </b-card-text>
                <p>Describe your item(s).</p>
                <!-- <p class="text-danger">Need help posting order?</p> -->
              </div>
              <div class="col-xs-8 col-sm-8 col-md-5 col-lg-5">
                <b-container
                  class="rounded box-shadow-1 d-flex justify-content-center"
                >
                  <validation-observer ref="SummaryRules">
                    <b-card>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="ITEM(S) DETAILS">
                                <validation-provider
                                  #default="{ errors }"
                                  name="item detail"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-input
                                    v-model="form.item_detail"
                                    name="item detail"
                                    type="text"
                                    placeholder="Item details"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="d-flex w-100">
                              <b-form-group label="ITEM(S) SIZE">
                                <validation-provider
                                  #default="{ errors }"
                                  name="item size"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-radio-group
                                    v-model="form.item_size"
                                    :options="item_size"
                                    value-field="value"
                                    text-field="title"
                                    disabled-field="notEnabled"
                                  ></b-form-radio-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="d-flex w-100">
                              <b-form-group label="VEHICLE REQUIRED">
                                <validation-provider
                                  #default="{ errors }"
                                  name="vehicle"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-radio-group
                                    v-model="form.vehicle"
                                    :options="vehicle"
                                    value-field="value"
                                    text-field="title"
                                    @change="logpoint"
                                  ></b-form-radio-group>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="d-flex w-100">
                              <b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Door to door service"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-checkbox
                                    v-model="form.is_door2door"
                                    value="yes"
                                    unchecked-value="no"
                                    @change="onChange"
                                  >
                                    DOOR TO DOOR SERVICE
                                  </b-form-checkbox>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="d-flex w-100">
                              <b-form-group>
                                <b-form-checkbox
                                  v-model="form.uniqueorder"
                                  value="yes"
                                  unchecked-value="no"
                                >
                                  UNIQUE ORDER
                                </b-form-checkbox>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="d-flex w-100">
                              <b-form-group>
                                <validation-provider
                                  #default="{ errors }"
                                  name="Return trip"
                                  rules="required"
                                  class="w-100"
                                >
                                  <b-form-checkbox
                                    v-model="form.is_returnTrip"
                                    value="yes"
                                    unchecked-value="no"
                                    @change="onChange"
                                  >
                                    RETURN TRIP
                                  </b-form-checkbox>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="UNIQUE CODE">
                                <b-form-input
                                  v-model="form.unique_code"
                                  name="unique code"
                                  type="text"
                                  placeholder="Unique Code"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <h2>LOGPOINT COST</h2>
                          <b-row>
                            <b-col class="col-6 col-lg-4">
                              <b-form-group label="MOTOR">
                                <p
                                  type="text"
                                  class="form-control mr-2 mt-1 d-flex align-items-center"
                                >
                                  {{ form.logpoint_motor }}
                                </p>
                              </b-form-group>
                            </b-col>
                            <b-col class="col-6 col-lg-4">
                              <b-form-group label="CAR">
                                <p
                                  type="text"
                                  class="form-control mr-2 mt-1 d-flex align-items-center"
                                >
                                  {{ form.logpoint_car }}
                                </p>
                              </b-form-group>
                            </b-col>
                            <b-col class="col-6 col-lg-4">
                              <b-form-group label="4X4 TRUCK">
                                <p
                                  type="text"
                                  class="form-control mr-2 mt-1 d-flex align-items-center"
                                >
                                  {{ form.logpoint_truck }}
                                </p>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <p
                                type="text"
                                class="form-control mr-2 d-flex align-items-center bg-primary text-white"
                              >
                                Minimum Order Price (Motor / Car / Truck) : 5 /
                                10 / 30 LP
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="col-16">
                          <b-row>
                            <b-col class="w-100">
                              <b-form-group label="DURATION (ESTIMATES)">
                                <p
                                  type="text"
                                  class="form-control mr-2 mt-1 d-flex align-items-center"
                                >
                                  {{ logpointcost.duration }}
                                </p>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </validation-observer>
                </b-container>
              </div>
            </div>
          </b-card>
        </tab-content>
      </form-wizard>
      <template #overlay>
        <div class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
            variant="primary"
          ></b-spinner>
          <h3 class="mt-3">Creating your order. Please wait...</h3>
        </div>
      </template></b-overlay
    >
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required } from "@validations";
import { codeIcon } from "./code";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
  },
  data() {
    return {
      isSubmitting: false,
      item_image: "",
      form: {
        logpoint: "",
        logpoint_motor: "",
        logpoint_car: "",
        logpoint_truck: "",
        delivery_date: "",
        delivery_time: "",
        order_type: "Ondemand",
        is_door2door: "no",
        is_returnTrip: "no",
        deliveries: [
          {
            first_name: "",
            last_name: "",
            contact: "",
            address: "",
            remarks: "",
          },
        ],
      },
      item_size: [
        {
          title: "SMALL SIZE",
          value: "small_size",
        },
        {
          title: "MEDIUM SIZE",
          value: "medium_size",
        },
        {
          title: "LARGE SIZE",
          value: "large_size",
        },
      ],
      vehicle: [
        {
          title: "MOTOR",
          value: "motor",
        },
        {
          title: "CAR",
          value: "car",
        },
        {
          title: "4X4 TRUCK",
          value: "truck",
        },
      ],
      yesno: [
        { title: "YES", value: "yes" },
        { title: "NO", value: "no" },
      ],
      logpointcost: {
        duration: "",
      },
    };
  },
  mounted() {
    this.form.delivery_time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  },
  methods: {
    onFileUpload(e) {
      this.item_image = e.target.files[0];
    },
    formSubmitted() {
      this.isSubmitting = true;
      if (this.form.vehicle == "motor") {
        this.form.logpoint = this.form.logpoint_motor;
      } else if (this.form.vehicle == "car") {
        this.form.logpoint = this.form.logpoint_car;
      } else {
        this.form.logpoint = this.form.logpoint_truck;
      }
      let formData = new FormData();
      formData.append("address", this.form.address);
      formData.append("first_name", this.form.first_name);
      formData.append("last_name", this.form.last_name);
      formData.append("contact", this.form.contact);
      formData.append("order_type", this.form.order_type);
      formData.append("is_door2door", this.form.is_door2door);
      formData.append("is_returnTrip", this.form.is_returnTrip);
      formData.append("delivery_date", this.form.pickup_date);
      formData.append("delivery_time", this.form.pickup_time);
      formData.append("pickup_date", this.form.pickup_date);
      formData.append("pickup_time", this.form.pickup_time);
      formData.append("item_size", this.form.item_size);
      formData.append("remarks", this.form.remarks);
      formData.append("logpoint_motor", this.form.logpoint_motor);
      formData.append("logpoint_car", this.form.logpoint_car);
      formData.append("logpoint_truck", this.form.logpoint_truck);
      formData.append("logpoint", this.form.logpoint);
      formData.append("vehicle", this.form.vehicle);
      formData.append(
        "deliveries[0][address]",
        this.form.deliveries[0].address
      );
      formData.append(
        "deliveries[0][first_name]",
        this.form.deliveries[0].first_name
      );
      formData.append(
        "deliveries[0][last_name]",
        this.form.deliveries[0].last_name
      );
      formData.append(
        "deliveries[0][contact]",
        this.form.deliveries[0].contact
      );
      formData.append(
        "deliveries[0][remarks]",
        this.form.deliveries[0].remarks
      );
      formData.append(
        "deliveries[0][item_image]",
        this.item_image,
        "on_demand.png"
      );
      this.$http
        .post(`order/create`, formData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.$router.push({
              name: "on-demand-delivery-success",
            });
          } else {
            if (response.data.errors) {
              var errors = response.data.errors;
              for (var a in errors) {
                var error = errors[a];
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error[0],
                    icon: "XIcon",
                    variant: "warning",
                  },
                });
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "XIcon",
                  variant: "warning",
                },
              });
            }
          }
          this.isSubmitting = false;
        });
    },
    PickupForm() {
      return new Promise((resolve, reject) => {
        this.$refs.PickupRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    DeliveryForm() {
      return new Promise((resolve, reject) => {
        this.$refs.DeliveryRules.validate().then((success) => {
          if (success) {
            this.$http
              .post(`get_ondemand_pricing`, this.form)
              .then((response) => {
                this.form.logpoint_motor = response.data.data.total_LP_motor;
                this.form.logpoint_car = response.data.data.total_LP_car;
                this.form.logpoint_truck = response.data.data.total_LP_truck;
                this.logpointcost.duration = response.data.data.total_duration;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    SummaryForm() {
      return new Promise((resolve, reject) => {
        this.$refs.SummaryRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onChange() {
      this.$http.post(`get_ondemand_pricing`, this.form).then((response) => {
        this.form.logpoint_motor = response.data.data.total_LP_motor;
        this.form.logpoint_car = response.data.data.total_LP_car;
        this.form.logpoint_truck = response.data.data.total_LP_truck;
        this.logpointcost.duration = response.data.data.total_duration;
      });
    },
    logpoint() {
      if (this.form.vehicle == "motor") {
        this.form.logpoint = this.form.logpoint_motor;
      } else if (this.form.vehicle == "car") {
        this.form.logpoint = this.form.logpoint_car;
      } else {
        this.form.logpoint = this.form.logpoint_truck;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
